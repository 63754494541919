import {
  Brand,
  type Cart,
  type GtmLineItem,
  GtmLineItemCategory,
  GtmLineItemCategory2,
  HardwareCategory,
  HardwareCondition,
  type LineItem,
  type LineItemHardware,
  type MappedHardwareListItem,
  type Offer,
  OfferType,
  type OrderRow,
  type OrderRowHardware,
  type Product,
  ProductRatePlan,
  ProductType,
  type Subscription,
  SubscriptionType,
  type Topup,
} from '@/types';

import { getHardwareCondition } from '@/pagewrappers/Hardware/Hardware.utils';

const getItemCategory = (ratePlan?: ProductRatePlan | null) => {
  if (!ratePlan) return;

  const categories = {
    Contract: GtmLineItemCategory.Recurring,
    NonContract: GtmLineItemCategory.Prepaid,
    Hardware: GtmLineItemCategory.Hw,
  };

  return categories[ratePlan];
};
const getItemCategoryFromSubscription = (ratePlan: SubscriptionType) => {
  const categories = {
    Cashcard: GtmLineItemCategory.Prepaid,
    BroadbandCashcard: GtmLineItemCategory.Prepaid,
    Broadband: GtmLineItemCategory.Recurring,
    Mobile: GtmLineItemCategory.Recurring,
    Unknown: GtmLineItemCategory.Unknown,
  };

  return categories[ratePlan];
};

const getItemCategory2 = (productType?: ProductType | null) => {
  if (!productType) return;

  const categories = {
    Broadband: GtmLineItemCategory2.Broadband,
    Voice: GtmLineItemCategory2.Phoneplan,
    Mobile: GtmLineItemCategory2.Mobile,
    Hardware: GtmLineItemCategory2.Hardware,
    Router: GtmLineItemCategory2.Router,
    unknown: GtmLineItemCategory2.Unknown,
  };

  return categories[productType] as GtmLineItemCategory2;
};

const getItemCategoryFromSubscription2 = (productType: SubscriptionType) => {
  const categories = {
    Cashcard: GtmLineItemCategory2.Phoneplan,
    Broadband: GtmLineItemCategory2.Broadband,
    BroadbandCashcard: GtmLineItemCategory2.Broadband,
    Mobile: GtmLineItemCategory2.Mobile,
    Unknown: GtmLineItemCategory2.Unknown,
  };

  return categories[productType] as GtmLineItemCategory2;
};

export const generateGtmLineItemFromRaw = (
  data: Offer,
  product?: Product,
  itemListName = '',
  index = 0,
  itemBrand = Brand.B2C
): GtmLineItem => {
  const itemCategory = getItemCategory(product?.ratePlan);
  const itemCategory2 = getItemCategory2(product?.type);

  const getDiscount = () => {
    if (!product?.price?.amount) return 0;
    return product?.price && data.offerPrice ? product.price.amount - parseInt(data.offerPrice ?? '0', 10) : 0;
  };

  return {
    item_id: product?.id ?? '',
    item_name: product?.name ?? '',
    coupon: data.campaignKeys ?? '',
    currency: 'SEK',
    discount: getDiscount(),
    index,
    item_brand: product?.brand ?? itemBrand,
    item_category: itemCategory,
    item_category2: itemCategory2,
    item_list_name: itemListName,
    price: product?.price ? product?.price.amount : 0,
    quantity: 1,
  };
};

export const generateGtmLineItemFromMappedHardwareProduct = (
  hardwareProduct: Pick<MappedHardwareListItem, 'modelName' | 'variant' | 'brand' | 'category' | 'condition'>,
  hardwareConditions?: HardwareCondition[],
  index = 0,
  itemListName = ''
): GtmLineItem => {
  const { modelName, brand, category, variant, condition } = hardwareProduct;
  const { label } = getHardwareCondition(hardwareConditions, condition) || {};
  const conditionLabel = label ? `${label} ` : '';

  const { totalPrice, discountedTotalPrice } = variant.instalmentOptions[0].content;

  const itemName = `${conditionLabel}${brand} ${modelName}${
    variant.memorySize !== '0' ? ` ${variant.memorySize}` : ''
  } ${variant.colorName}`;
  return {
    item_id: variant.productReference,
    item_name: itemName,
    currency: 'SEK',
    discount: discountedTotalPrice !== '' ? Number(totalPrice) - Number(discountedTotalPrice) : 0,
    index,
    item_brand: brand,
    item_category: GtmLineItemCategory.Hw,
    item_category2: category === HardwareCategory.Mobile ? GtmLineItemCategory2.Mobile : GtmLineItemCategory2.Router,
    item_list_name: itemListName,
    price: Number(totalPrice),
    quantity: 1,
  };
};

export const generateGtmHardwareLineItem = (
  lineItemHardware: LineItemHardware,
  hardwareConditions?: HardwareCondition[],
  index = 0
): GtmLineItem => {
  const { modelName, colorName, memorySize, productReference, brand, productType, partPaymentPrice, condition } =
    lineItemHardware;

  const { label } = getHardwareCondition(hardwareConditions, condition) || {};
  const conditionLabel = label ? `${label} ` : '';

  const itemName = `${conditionLabel}${brand} ${modelName}${memorySize !== '0' ? ` ${memorySize}` : ''} ${colorName}`;

  return {
    item_id: productReference.split('-')[1],
    item_name: itemName,
    coupon: '',
    currency: 'SEK',
    discount: partPaymentPrice.discountedTotalPriceInclVat
      ? partPaymentPrice.totalPriceInclVat - partPaymentPrice.discountedTotalPriceInclVat
      : 0,
    index,
    item_brand: brand,
    item_category: GtmLineItemCategory.Hw,
    item_category2: productType === ProductType.Mobile ? GtmLineItemCategory2.Mobile : GtmLineItemCategory2.Router,
    item_list_name: '',
    price: partPaymentPrice.totalPriceInclVat,
    quantity: 1,
  };
};

export const generateGtmLineItemFromCartLineItem = (
  cartLineItem: LineItem,
  hardwareConditions?: HardwareCondition[],
  index = 0,
  itemBrand = Brand.B2C
): GtmLineItem => {
  if (cartLineItem.hardware) return generateGtmHardwareLineItem(cartLineItem.hardware, hardwareConditions, index);

  // otherwise it's an offer
  const itemCategory = getItemCategory(cartLineItem.ratePlan);
  const itemCategory2 = getItemCategory2(cartLineItem.productType);
  return {
    item_id: cartLineItem.packageId,
    item_name: cartLineItem.name,
    coupon: cartLineItem.campaignKeys,
    currency: 'SEK',
    discount: cartLineItem.campaign && cartLineItem.price ? cartLineItem.price - cartLineItem.campaign.price : 0,
    index,
    item_brand: itemBrand,
    item_category: itemCategory,
    item_category2: itemCategory2,
    item_list_name: '',
    price: cartLineItem?.price || undefined,
    quantity: 1,
  };
};

export const getGtmCartItems = (cart: Cart, hardwareConditions?: HardwareCondition[]) => {
  const offerLineItem = cart.lineItems.find((lineItem) => lineItem.lineItemType === OfferType.Offer) as LineItem;
  const hwLineItem = cart.lineItems.find((lineItem) => lineItem.lineItemType === OfferType.Hardware) as LineItem;
  const hasExistingSubscription = !!offerLineItem?.existingSubscription;

  if (hwLineItem) {
    // We only return a gtmHwLineItem if there is an existing subscription
    return hasExistingSubscription
      ? [generateGtmLineItemFromCartLineItem(hwLineItem, hardwareConditions, undefined, cart.customerContext)]
      : [
          generateGtmLineItemFromCartLineItem(offerLineItem, undefined, undefined, cart.customerContext),
          generateGtmLineItemFromCartLineItem(hwLineItem, hardwareConditions, undefined, cart.customerContext),
        ];
  }

  return [generateGtmLineItemFromCartLineItem(offerLineItem, undefined, undefined, cart.customerContext)];
};

const generateHardwareGtmLineItemFromOrderRow = (
  orderRowHardware: OrderRowHardware,
  index = 0,
  hardwareConditions?: HardwareCondition[]
): GtmLineItem => {
  const { modelName, colorName, memorySize, productReference, brand, productType, partPaymentPrice, condition } =
    orderRowHardware;
  const { label } = getHardwareCondition(hardwareConditions, condition) || {};
  const conditionLabel = label ? `${label} ` : '';
  const itemName = `${conditionLabel}${brand} ${modelName}${memorySize !== '0' ? ` ${memorySize}` : ''} ${colorName}`;
  return {
    item_id: productReference.split('-')[1],
    item_name: itemName,
    coupon: '',
    currency: 'SEK',
    discount: partPaymentPrice.discountedTotalPriceInclVat
      ? partPaymentPrice.totalPriceInclVat - partPaymentPrice.discountedTotalPriceInclVat
      : 0,
    index,
    item_brand: brand,
    item_category: GtmLineItemCategory.Hw,
    item_category2: productType === ProductType.Mobile ? GtmLineItemCategory2.Mobile : GtmLineItemCategory2.Router,
    item_list_name: '',
    price: partPaymentPrice.pricePerMonthInclVat * partPaymentPrice.instalments,
    quantity: 1,
  };
};

export const generateGtmLineItemFromOrderRow = (
  orderRow: OrderRow,
  index = 0,
  itemBrand = Brand.B2C,
  hardwareConditions?: HardwareCondition[]
): GtmLineItem => {
  if (orderRow.hardware) return generateHardwareGtmLineItemFromOrderRow(orderRow.hardware, index, hardwareConditions);

  const itemCategory = getItemCategory(orderRow.ratePlan);
  const itemCategory2 = getItemCategory2(orderRow.productType);
  const discount = orderRow.campaign ? Number(orderRow.originalPrice) - orderRow.campaign.price : 0;

  return {
    item_id: orderRow.packageId,
    item_name: orderRow.name,
    coupon: orderRow.campaignKeys,
    currency: 'SEK',
    discount,
    index,
    item_brand: itemBrand,
    item_category: itemCategory,
    item_category2: itemCategory2,
    item_list_name: '',
    price: orderRow.originalPrice,
    quantity: 1,
  };
};

export const getGtmOrderItems = (
  orderRows: OrderRow[],
  itemBrand = Brand.B2C,
  hardwareConditions?: HardwareCondition[]
) => {
  const offerLineItem = orderRows.find((orderRow) => orderRow.lineItemType === OfferType.Offer) as OrderRow;
  const hwLineItem = orderRows.find((orderRow) => orderRow.lineItemType === OfferType.Hardware) as OrderRow;
  const hasExistingSubscription = !!offerLineItem?.existingSubscription;

  if (hwLineItem) {
    // We only return a gtmHwLineItem if there is an existing subscription
    return hasExistingSubscription
      ? [generateGtmLineItemFromOrderRow(hwLineItem, undefined, undefined, hardwareConditions)]
      : [
          generateGtmLineItemFromOrderRow(offerLineItem, 0, itemBrand),
          generateGtmLineItemFromOrderRow(hwLineItem, undefined, undefined, hardwareConditions),
        ];
  }

  return [generateGtmLineItemFromOrderRow(offerLineItem, 0, itemBrand)];
};

export const generateGtmLineItemFromTopup = (
  topup: Topup,
  subscription: Subscription,
  itemListName = '',
  index = 0,
  itemBrand = Brand.B2C
): GtmLineItem => {
  const itemCategory = getItemCategoryFromSubscription(subscription.type);
  const itemCategory2 = getItemCategoryFromSubscription2(subscription.type);

  return {
    item_id: topup.productId,
    item_name: topup.name,
    coupon: undefined,
    currency: 'SEK',
    discount: 0,
    index,
    item_brand: itemBrand,
    item_category: itemCategory,
    item_category2: itemCategory2,
    item_list_name: itemListName,
    price: topup.price.amount,
    quantity: 1,
  };
};

export const getTotalCartValue = (items: GtmLineItem[]) => {
  return items.reduce((total, { price, discount }) => total + (Number(price) - discount), 0);
};
